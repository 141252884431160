import React, { useEffect, useState } from "react";
import { Navbar as BsNavbar, Nav, Container } from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useLocation } from "@reach/router";
import routes from "@utils/routes";

const query = graphql`
  query NavQuery {
    logo: file(relativePath: { eq: "logo-right.png" }) {
      publicURL
    }
    hamburgerIcon: file(relativePath: { eq: "icon/hamburger.svg" }) {
      publicURL
    }
    iconMail: file(relativePath: { eq: "icon/mail.png" }) {
      publicURL
    }
    iconFB: file(relativePath: { eq: "icon/facebook.png" }) {
      publicURL
    }
    btnWA: file(relativePath: { eq: "icon/whatsapp.png" }) {
      publicURL
    }
  }
`;

const Navbar = () => {
  const [showBackgroundColor, setShowBackgroundColor] = useState<boolean>(
    false
  );
  const { pathname, search } = useLocation();
  const { logo, hamburgerIcon, iconMail, iconFB, btnWA } = useStaticQuery(
    query
  );

  function onScroll() {
    if (window.scrollY > 0) setShowBackgroundColor(true);
    else setShowBackgroundColor(false);
  }

  const isCurrentPage = (route: string) => {
    return route === pathname ? "active" : "";
  };

  const renderDesktopNav = () => {
    return routes.slice(0, 4).map(route => {
      return (
        <Nav.Link
          as={Link}
          to={`${route.path}${search}`}
          key={route.path}
          className={isCurrentPage(route.path)}
        >
          {route.label}
        </Nav.Link>
      );
    });
  };

  useEffect(() => {
    onScroll();

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <BsNavbar expand="lg" className="mt-4">
      <Container
        className={`shadow bg-light ${showBackgroundColor ? "show" : ""} ${
          pathname === "/portofolio/" ? "normal" : ""
        }`}
      >
        <BsNavbar.Brand as={Link} to={`/${search}`}>
          <img src={logo.publicURL} alt="logo" />
        </BsNavbar.Brand>
        <BsNavbar.Toggle aria-controls="main-navbar">
          <span
            className="navbar-toggler-icon"
            style={{
              backgroundImage: `url(${hamburgerIcon.publicURL})`,
            }}
          />
        </BsNavbar.Toggle>
        <BsNavbar.Collapse id="main-navbar" className="desktop-menu">
          <Nav className="ml-auto navbar-nav main-menu align-items-center">
            {renderDesktopNav()}
            <Nav.Link className="ml-5 social-media" as="div">
              <div className="d-flex align-items-center justify-content-center">
                <a href="mailto:supertrisno@gmail.com" className="mx-2">
                  <img src={iconMail.publicURL} alt="mail" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/MobilAmbulanceoManisanCaricaDiJakartaWonosobo"
                  className="mx-2"
                >
                  <img src={iconFB.publicURL} alt="fb" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wa.me/0811101505?text=Halo Pak Trisno, saya ingin tanya perihal mobil ambulance."
                  className="mx-2"
                >
                  <img src={btnWA.publicURL} alt="wa" />
                </a>
              </div>
            </Nav.Link>
          </Nav>
        </BsNavbar.Collapse>
      </Container>
    </BsNavbar>
  );
};

export default Navbar;
