import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import SEO from "@components/SEO";
import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import { useLocation } from "@reach/router";

interface LayoutProps {
  noFooter?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, noFooter = false }) => {
  const { pathname } = useLocation();
  const [showContactBtn, setShowContactBtn] = useState<boolean>(false);

  function onScroll() {
    if (pathname !== "/") {
      setShowContactBtn(true);
      return;
    }

    if (window.scrollY > window.innerHeight - 200) setShowContactBtn(true);
    else setShowContactBtn(false);
  }

  useEffect(() => {
    onScroll();

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <main>
      <SEO />
      <Navbar />
      {children}
      {!noFooter && <Footer />}
      <Button
        variant="success"
        as="a"
        href="https://wa.me/0811101505?text=Halo Pak Trisno, saya ingin tanya perihal mobil ambulance."
        className={`floating-contact-us ${showContactBtn ? `show` : ``}`}
      >
        HUBUNGI KAMI
      </Button>
    </main>
  );
};

export default Layout;
