interface Routes {
  path: string;
  label: string;
  subMenu?: Routes[];
}

const routes: Routes[] = [
  {
    "path": "/",
    "label": "HOME",
  },
  {
    "path": "/profil/",
    "label": "PROFIL",
  },
  {
    "path": "/portofolio/",
    "label": "PORTOFOLIO",
  },
];

export default routes;
