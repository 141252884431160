import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";

const query = graphql`
  query FooterQuery {
    # QUERY LOGO
    logo: file(relativePath: { eq: "logo.png" }) {
      publicURL
    }
  }
`;

const Footer = () => {
  const { logo } = useStaticQuery(query);

  return (
    <footer className="py-5" id="footer">
      <Container>
        <Row className="justify-content-between">
          <Col md={8}>
            <img src={logo.publicURL} alt="logo" className={""} />
            <p>
              Kami berkomitmen membantu Anda dalam segala bentuk dan ukuran
              Mobil maupun berbagai merek mobil yang biasa digunakan untuk
              modifikasi ambulance seperti Toyota, Suzuki, Nissan, Daihatsu,
              Isuzu , Mitsubishi ,dll . Tanpa menganggu waktu Anda yang begitu
              berharga untuk cara pemesanan maupun cara pengiriman untuk semua
              produk kami. Apalagi dengan kondisi lalu lintas kota besar yang
              sering mengalami macet. Dan kondisi geografis Indonesia terdiri
              dari ribuan kepulauan kami hadir untuk mempermudah Anda dan Bisa
              Bekerjasama menjadi Mitra Rekanan yang sangat baik.
            </p>
          </Col>
          <Col md={3}>
            <h2>HUBUNGI KAMI:</h2>
            <div className="footer-contact">
              <h3>Customer Care:</h3>
              <p>08111014505</p>
              <h3>Email:</h3>
              <p>supertrisno@gmail.com</p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
